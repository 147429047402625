import "./Login.scss";

import { Button, Heading, Paragraph, useThemeName } from "@blox/design-system";
import { OidcClient } from "oidc-client-ts";
import { useCallback } from "react";
import { useAuth } from "react-oidc-context";

import { AppContent, AppPage } from "../../App.style";
import { ReactComponent as SocialApple } from "../../assets/img/login-apple.svg";
import { ReactComponent as SocialFacebook } from "../../assets/img/login-facebook.svg";
import { ReactComponent as SocialGoogle } from "../../assets/img/login-google.svg";
import { ReactComponent as SocialGoogleLight } from "../../assets/img/login-google-light.svg";
import { ReactComponent as SocialLinkedin } from "../../assets/img/login-linkedin.svg";
import { ReactComponent as SocialLinkedinLight } from "../../assets/img/login-linkedin-light.svg";
import loginVisual from "../../assets/img/login-visual.png";
import Footer from "../../components/Footer/Footer";
import LanSelector from "../../components/LanSelector/LanSelector";
import Logo from "../../components/Logo/Logo";
import { Col, Row } from "../../components/UI/Columns/Columns";
import { ModalContent } from "../../components/UI/Modal/Modal";
import { useLocalisation } from "../../context/LocalizeProvider";
import { useModal } from "../../context/ModalProvider";
import { useFirestore } from "../../hooks/useFirestore";
import { useLocale } from "../../hooks/useStore";
import RiskBanner from "./RiskBanner/RiskBanner";

function Login() {
  const t = useLocalisation();
  const locale = useLocale();
  const { signinRedirect, settings } = useAuth();
  const disableRegister = useFirestore((store) => store.config.configSet.disableRegister);
  const colorScheme = useThemeName();

  const login = useCallback(
    async (providerId?: string) => {
      await signinRedirect({
        redirect_uri:
          new URLSearchParams(window.location.search).get("redirectUrl") ?? window.location.origin,
        extraQueryParams: {
          ui_locales: locale,
          kc_idp_hint: providerId!,
          colorScheme,
        },
      });
    },
    [locale, signinRedirect, colorScheme]
  );

  const register = useCallback(async () => {
    localStorage.setItem("loginMode", "register");

    const redirectUrl = new URL(window.location.toString());

    const client = new OidcClient(settings);
    const metadata = await client.metadataService.getMetadata();

    const { url } = await client.createSigninRequest({
      ui_locales: locale,
      redirect_uri: redirectUrl.toString(),
      request_type: "si:r",
      extraQueryParams: {
        colorScheme,
      },
    });

    const registerUrl = url.replace(
      metadata.authorization_endpoint ?? "",
      metadata.issuer + "/protocol/openid-connect/registrations"
    );

    window.location.assign(registerUrl);
  }, [locale, settings, colorScheme]);

  const { openModal } = useModal();

  const showSocialLoginModal = () => {
    openModal(
      <ModalContent>
        <Paragraph>
          <Button
            variant="flat"
            Icon={SocialFacebook}
            onClick={() => login("facebook")}
            className="social__login social__login--facebook"
          >
            {t.text("Login", "signInWith")} Facebook
          </Button>
        </Paragraph>
        <Paragraph>
          <Button
            variant="flat"
            Icon={colorScheme === "dark" ? SocialGoogleLight : SocialGoogle}
            onClick={() => login("google")}
            className="social__login social__login--google"
          >
            {t.text("Login", "signInWith")} Google
          </Button>
        </Paragraph>
        <Paragraph>
          <Button
            variant="flat"
            Icon={colorScheme === "dark" ? SocialLinkedinLight : SocialLinkedin}
            onClick={() => login("linkedin")}
            className="social__login social__login--linkedin"
          >
            {t.text("Login", "signInWith")} LinkedIn
          </Button>
        </Paragraph>
        <Paragraph>
          <Button
            variant="flat"
            Icon={SocialApple}
            onClick={() => login("apple")}
            className="social__login social__login--apple"
          >
            {t.text("Login", "signInWith")} Apple
          </Button>
        </Paragraph>
      </ModalContent>,
      {
        noCancelButton: true,
        headerTitle: t.text("Login", "socialSignIn"),
      }
    );
  };

  return (
    <>
      <RiskBanner />
      <AppContent height="100vh" flexDirection="column">
        <a href="https://weareblox.com" className="login__logo">
          <Logo />
        </a>
        <AppPage display="flex" flexDirection="column" flexGrow={1} justifyContent="center">
          <Row cols={12}>
            <Col span={5} className="login__signin-form">
              <Heading level={1} $excludeTablet={{ fontSize: 72 }}>
                {t.text("Login", "welcome")}
              </Heading>
              <Paragraph variant={["fadedBlue", "small"]}>{t.text("Login", "newPlatform")}</Paragraph>
              <Button variant="primary" onClick={() => login()} marginVertical="$10">
                {t.text("Login", "signIn")}
              </Button>
              <Button variant="red" onClick={() => showSocialLoginModal()} marginVertical="$10">
                {t.text("Login", "socialSignIn")}
              </Button>
              {!disableRegister && (
                <>
                  <div className="login__or">
                    <div />
                    <Paragraph>{t.text("Login", "or")}</Paragraph>
                    <div />
                  </div>
                  <Button variant="secondary" onClick={register} marginVertical="$10">
                    {t.text("Login", "createAccountBtn")}
                  </Button>
                </>
              )}
            </Col>
            <Col span={5} className="hide-on-tablet" style={{ gridColumn: "7 / span 5" }}>
              <img src={loginVisual} alt="" />
            </Col>
          </Row>
        </AppPage>

        <Footer />
        <div className="login__lan-selector hide-on-mobile">
          <LanSelector noLabel />
        </div>
      </AppContent>
    </>
  );
}

export default Login;
